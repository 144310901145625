import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";
import SleepyNewsAnchor from "../../components/SleepyNewsAnchor";

const NewsPage = () => (
	<Layout>
		<Seo title="March 2022" />
		<NewsHeader />
		<h2>March 2022</h2>

		<h3 className="blogdate">
			Thursday, March 3, 2022
		</h3>

		<h4 className="blogitemtitle">
			<SleepyNewsAnchor num="1">New Release: Blue Skies (Irving Berlin)</SleepyNewsAnchor>
		</h4>
		<div className="blogitembody">
			<p>
				Continuing with my plan to release covers (and originals) more frequently—in hope that an algorithm
				might offer my music to more new listeners—I present a song that I once thought was by Willie Nelson,
				but actually is by Irving Berlin.
				And it's an oldie! Like 1926 old!
				It is... <span className="italic">Blue Skies</span>.
			</p>
			<p>
				Please find it wherever you prefer to stream or buy digital music.
				You might do that while also reading my release notes at <Link to="/music/10/">the release page</Link>.
			</p>
			<br />
			<div className="main--centerwrapper">
			<StaticImage
				src="../../images/catalog artwork/JRIVEST10 blue skies front.jpg"
				alt="Jesse Rivest - Blue Skies - cover art"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={300}
			/></div>
		</div>

		<p>
		<Link to="/news/">Go back to the news index page</Link>
		</p>
	</Layout>
);

export default NewsPage;
